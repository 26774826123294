export const languages = {
    DE: "German",
    EN: "English",
};

export const flashcardModes = {
    "Q/A - Pairs": "DETAILED_IN_BULLET_POINTS",
    "More coming...": "",
};

export const summaryModes = {
    "Bullets - detailed": "DETAILED_IN_BULLET_POINTS",
    "Bullets - short": "SHORT_IN_BULLET_POINTS",
    "Text - short": "SHORT_IN_TEXT",
};
