import lottie from "lottie-web";
import React, { useEffect, useState } from "react";
import * as animationDataA from "../likeAnimation.json";
import styles from "./feedbackmodal.module.css";
import OverlayModal from "./OverlayModal";

const Star = ({ selected = false, hovered = false, onClick, onMouseEnter, onMouseLeave }) => {
    return (
        <span onClick={onClick} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <svg
                className={`${styles.star} ${selected ? styles.star_selected : ""} ${
                    hovered ? styles.star_hovered : ""
                }`}
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="22"
                viewBox="0 0 23 22"
                fill="none">
                <path
                    d="M9.24039 4.52177C10.1979 2.17392 10.6767 1 11.5 1C12.3233 1 12.8021 2.17393 13.7596 4.52178L13.8042 4.63111C14.3452 5.95752 14.6156 6.62073 15.1669 7.02384C15.7182 7.42695 16.4418 7.49069 17.8891 7.61817L18.1507 7.64122C20.5194 7.84986 21.7037 7.95418 21.9571 8.69527C22.2105 9.43636 21.331 10.2234 19.5719 11.7974L18.9849 12.3228C18.0944 13.1196 17.6492 13.518 17.4416 14.0401C17.4029 14.1375 17.3707 14.2373 17.3453 14.3389C17.2089 14.8832 17.3393 15.4612 17.6 16.6171L17.6812 16.9769C18.1604 19.1013 18.4 20.1635 17.9817 20.6216C17.8253 20.7928 17.6222 20.9161 17.3964 20.9767C16.7924 21.1388 15.9348 20.4515 14.2197 19.0769C13.0935 18.1743 12.5304 17.723 11.8839 17.6215C11.6296 17.5815 11.3704 17.5815 11.1161 17.6215C10.4696 17.723 9.9065 18.1743 8.78028 19.0769C7.06515 20.4515 6.20759 21.1388 5.60356 20.9767C5.37784 20.9161 5.17466 20.7928 5.01833 20.6216C4.59997 20.1635 4.83958 19.1013 5.3188 16.9769L5.39997 16.6171C5.66073 15.4612 5.79111 14.8832 5.6547 14.3389C5.62926 14.2373 5.59707 14.1375 5.55836 14.0401C5.35084 13.518 4.90561 13.1196 4.01514 12.3228L3.42805 11.7974C1.66899 10.2234 0.789457 9.43636 1.04287 8.69527C1.29629 7.95418 2.48062 7.84986 4.84928 7.64122L5.11092 7.61817C6.5582 7.49069 7.28184 7.42695 7.8331 7.02384C8.38436 6.62073 8.65484 5.95752 9.1958 4.6311L9.24039 4.52177Z"
                    stroke="#256EFF"
                    stroke-opacity="0.95"
                    stroke-width="1.5"
                />
            </svg>
        </span>
    );
};

const Rating = ({ totalStars = 5, rating, setRating, hoverAt, setHoverAt }) => {
    return (
        <div className={styles.rating}>
            {[...Array(totalStars)].map((_, i) => (
                <Star
                    key={i}
                    selected={i < rating}
                    hovered={i < hoverAt && hoverAt > 0}
                    onClick={() => setRating(i + 1)}
                    onMouseEnter={() => setHoverAt(i + 1)}
                    onMouseLeave={() => setHoverAt(0)}
                />
            ))}
        </div>
    );
};

const FeedbackModal = ({ isVisible, onClose, submitFeedback }) => {
    const [rating, setRating] = useState(0);
    const [hoverAt, setHoverAt] = useState(0);
    const [feedback, setFeedback] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const handleSubmitButton = async () => {
        setIsSubmitting(true); // Start submitting
        await submitFeedback(feedback, rating); // Simulate feedback submission
        setIsSubmitting(false);
        setSubmitSuccess(true); // Set success state
        setTimeout(() => {
            setSubmitSuccess(false);
            onClose();
        }, 5000); // Reset after 5 seconds
    };

    useEffect(() => {
        let animation;
        if (submitSuccess && isVisible) {
            animation = lottie.loadAnimation({
                container: document.getElementById("likeAnimation"),
                renderer: "svg",
                loop: true,
                autoplay: true,
                width: 50,
                height: 50,
                animationData: animationDataA,
            });
        }
        return () => animation?.destroy();
    }, [submitSuccess, isVisible]);

    const handleFeedbackChange = (event) => {
        setFeedback(event.target.value);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <OverlayModal isVisible={isVisible} onClose={onClose} title={"Feedback"}>
            {submitSuccess ? (
                <div className={styles.animationcontainer}>
                    <div id="likeAnimation" className={styles.likeanimation}></div>
                    <span className={styles.liketext}>Thank you, we appreciate your feedback!</span>
                </div>
            ) : (
                <>
                    <div>
                        <textarea
                            className={styles.feedback}
                            placeholder="(Optional) Leave your feedback here in your preferred language..."
                            value={feedback}
                            onChange={handleFeedbackChange}
                            rows="10"
                        />
                    </div>
                    <div className={styles.ratingAndSubmit}>
                        <div className={styles.rating}>
                            <Rating
                                rating={rating}
                                setRating={setRating}
                                hoverAt={hoverAt}
                                setHoverAt={setHoverAt}
                            />
                        </div>
                        <div
                            className={`${styles.submitbutton} ${
                                rating === 0 || isSubmitting ? styles.deactivated : ""
                            } ${isSubmitting ? styles.submitting : ""} ${
                                submitSuccess ? styles.success : ""
                            }`}
                            onClick={() => handleSubmitButton()}>
                            <span>
                                {isSubmitting
                                    ? "Submitting..."
                                    : submitSuccess
                                    ? "Submitted!"
                                    : "Submit Feedback"}
                            </span>
                        </div>
                    </div>
                </>
            )}
        </OverlayModal>
    );
};

export default FeedbackModal;
