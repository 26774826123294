import React, { useRef, useState } from "react";
import HeroIcon from "../HeroIcon";
import styles from "./startlayer.module.css";

function StartLayer({ handleUploadButtonClick, handleUploadDefault, onToggleFileManager }) {
    const [isDragging, setIsDragging] = useState(false);
    const uploadContainerRef = useRef(null); // Create a ref for the upload container

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.target.closest(`[data-icon='uploadIcon']`)) {
            return;
        }
        if (uploadContainerRef.current && !uploadContainerRef.current.contains(e.relatedTarget)) {
            // Only trigger when the drag enters the container from outside
            setIsDragging(true);
        }
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (uploadContainerRef.current && !uploadContainerRef.current.contains(e.relatedTarget)) {
            // Only trigger when the drag leaves the container to the outside
            setIsDragging(false);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault(); // Still necessary to prevent default behavior
        e.stopPropagation();
        if (e.target.closest(`[data-icon='uploadIcon']`)) {
            return;
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const fileEvent = {
                target: {
                    files: [e.dataTransfer.files[0]],
                },
            };
            handleUploadDefault(fileEvent); // Assuming this method can handle the 'files' from the event.
            e.dataTransfer.clearData();
        }
    };

    return (
        <div
            ref={uploadContainerRef}
            className={`${styles.uploadContainer} ${isDragging ? styles.dragOver : ""}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}>
            {isDragging ? (
                <div className={styles.dragOverMessage}>Drag file here to upload</div>
            ) : (
                <>
                    <span className={styles.uploadIcon}>
                        <HeroIcon name="FolderUpLightIcon" />
                    </span>
                    <h2 className={styles.uploadTextTitle}>
                        Start your Learning Journey. <br />
                        It’s all set to accelerate your learning in minutes.
                    </h2>
                    <div className={styles.uploadArea}>
                        <button
                            className={styles.uploadBtn}
                            onClick={() => {
                                handleUploadButtonClick();
                            }}>
                            Upload Document
                        </button>
                        <button
                            className={styles.uploadBtn}
                            onClick={() => {
                                onToggleFileManager();
                            }}>
                            Open Filebrowser
                        </button>
                        <input
                            id="home"
                            type="file"
                            style={{ display: "none" }}
                            accept=".pdf,.docx,.DOCX,.doc,.DOC,.pptx,.PPTX,.ppt,.PPT,.odt,.ODT"
                            onChange={handleUploadDefault}
                        />
                    </div>
                </>
            )}
        </div>
    );
}

export default StartLayer;
