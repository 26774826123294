import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import React, { useEffect, useRef } from "react";
import Button from "../Button_v2/Button";
import buttonStyles from "../Button_v2/Button_V2.module.css";
import InlineCardModal from "../InlineCardModal/InlineCardModal";
import ModeTitleBar from "../ModeTitleBar/ModeTitleBar";
import styles from "./tinyeditor.module.css";

const BASE_API_ADDRESS = process.env.REACT_APP_API_GATEWAY_REST;
const SAVE_SUMMARY_TITLE_ENDPOINT = BASE_API_ADDRESS + "/save_summary_title";

const save_summary_title = async (document_id, summary_title) => {
    try {
        const response = await axios.get(SAVE_SUMMARY_TITLE_ENDPOINT, {
            params: {
                document_id: document_id,
                summary_title: summary_title,
            },
        });
        //console.log("Summary Title Saved");
    } catch (error) {
        console.log(error);
    }
};

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const TinyEditor = ({
    document_id,
    summary,
    onSummaryChange,
    summary_title,
    setSummaryTitle,
    toggleUpgradeModal,
    triggerSaveSummary,
    summaryStatus,
    isPaidUser,
    handleSummaryCreation,
    setCurrentAreas,
    setCurrentPageReference,
    toggleFeedbackModal,
    setFeedbackMode,
}) => {
    const editorRef = useRef(null);

    const onSummaryTitleChange = (title) => {
        setSummaryTitle(title);
        save_summary_title(document_id, title);
    };

    const base64_img_handler = (blobInfo) =>
        new Promise((resolve) => {
            resolve("data:image/png;base64," + blobInfo.base64());
        });

    useEffect(() => {
        const interval = setInterval(() => {
            updateSummary();
        }, 30 * 1000); // 30 000 milliseconds = 30 seconds

        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, []);

    const updateSummary = async () => {
        //console.log("updateSummary triggered");
        const editor = editorRef.current;
        if (!editor) return;

        // Store the current selection/cursor position
        const bookmark = editor.selection.getBookmark(2, true);

        onSummaryChange(editor.getContent());
        await delay(10);
        // Restore the cursor position
        editor.selection.moveToBookmark(bookmark);

        triggerSaveSummary();
    };

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles["editable-container"]}>
                    <ModeTitleBar
                        title={summary_title}
                        onTitleChange={onSummaryTitleChange}
                        placeHolder={"Title of your summary"}
                    />
                    <div className={styles["demo-dfree"]}>
                        <Editor
                            onInit={(evt, editor) => (editorRef.current = editor)}
                            licenseKey="gpl"
                            initialValue={summary}
                            onClick={(e) => {
                                //console.log(e.target.nodeName.toLowerCase());
                                if (e.target.nodeName.toLowerCase() === "abbr") {
                                    const pageNumber = parseInt(e.target.textContent);
                                    const coordinates = JSON.parse(
                                        e.target.getAttribute("data-coordinates")
                                    );
                                    coordinates.forEach((reference) => {
                                        reference["pageIndex"] = pageNumber - 1;
                                    });
                                    setCurrentPageReference(pageNumber);
                                    setCurrentAreas(coordinates);
                                }
                            }}
                            init={{
                                plugins: ["quickbars", "lists", "image", "table"],
                                toolbar: false,
                                menubar: false,
                                inline: true,
                                quickbars_selection_toolbar:
                                    "bold italic underline numlist bullist quickimage quicktable blocks alignleft aligncenter alignright",
                                quickbars_insert_toolbar: false,
                                autosave_ask_before_unload: true,
                                images_upload_handler: base64_img_handler,
                            }}
                            onBlur={() => {
                                updateSummary();
                            }}
                            onFocusOut={(e) => {
                                updateSummary();
                            }}
                            onMouseLeave={(e) => {
                                updateSummary();
                            }}
                        />
                    </div>
                    <div className={styles["test"]}></div>
                    {summaryStatus === "incomplete" && !isPaidUser && (
                        <div className={styles.upgradeCard}>
                            <InlineCardModal>
                                <p>This is a summary of the first 1000 words of your document.</p>
                                <p>Upgrade to get unlimited summaries</p>
                                <Button
                                    color={buttonStyles.btn_primary}
                                    onClick={toggleUpgradeModal}>
                                    Upgrade now!
                                </Button>
                            </InlineCardModal>
                        </div>
                    )}
                    {summaryStatus === "incomplete" && isPaidUser && (
                        <div className={styles.upgradeCard}>
                            <InlineCardModal>
                                <h3>Congratulations for upgrading to a premium plan</h3>
                                <p>Choose the language of your unlimited summary</p>
                                <Button
                                    color={buttonStyles.btn_primary}
                                    onClick={() =>
                                        handleSummaryCreation(
                                            document_id,
                                            "English",
                                            null,
                                            null,
                                            true
                                        )
                                    }>
                                    English
                                </Button>
                                <Button
                                    color={buttonStyles.btn_primary}
                                    onClick={() =>
                                        handleSummaryCreation(
                                            document_id,
                                            "German",
                                            null,
                                            null,
                                            true
                                        )
                                    }>
                                    German
                                </Button>
                                <p></p>
                            </InlineCardModal>
                        </div>
                    )}
                    {isPaidUser && (
                        <div className={styles.upgradeCard}>
                            <InlineCardModal>
                                <p>
                                    We made a lot of effort to support your summaries with images.
                                </p>
                                <p>Please share your Feedback with us!</p>
                                <Button
                                    onClick={() => {
                                        setFeedbackMode("multimedia_summary");
                                        toggleFeedbackModal();
                                    }}
                                    color={buttonStyles.btn_primary}>
                                    Feedback
                                </Button>
                            </InlineCardModal>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TinyEditor;
