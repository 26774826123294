import FlashcardsIcon from "./FlashcardsIcon";
import FolderUpLightIcon from "./FolderUpLightIcon";
import TextDocumentIcon from "./TextDocumentIcon";

const Icon = ({ name }) => {
    switch (name) {
        case "FolderUpLightIcon":
            return <FolderUpLightIcon />;
        case "TextDocumentIcon":
            return <TextDocumentIcon />;
        case "FlashcardsIcon":
            return <FlashcardsIcon />;
        default:
            return "";
    }
};

export default Icon;
