const UploadIcon = ({ width = 24, height = 24 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={`0 0 24 24`}
            fill="none">
            <path
                d="M17 10L12 5M12 5L7 10M12 5V14"
                stroke="#33363F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M5 16L5 17C5 18.1046 5.89543 19 7 19L17 19C18.1046 19 19 18.1046 19 17V16"
                stroke="#33363F"
                stroke-width="1.5"
                stroke-linecap="round"
            />
        </svg>
    );
};

export default UploadIcon;
