import Button from "../Button_v2/Button";
import buttonStyles from "../Button_v2/Button_V2.module.css";
import OverlayModal from "../OverlayModal/OverlayModal";
import styles from "./UpgradeModal.module.css";

const UpgradeModal = ({ isVisible, onClose, onClickUpgradeNow }) => {
    return (
        <OverlayModal isVisible={isVisible} onClose={onClose} width={900}>
            <div className={styles.headline}>
                Please upgrade to{" "}
                <span className={styles.gradientText}>get unlimited Summaries & Flashcards.</span>
            </div>
            <div className={styles.description}>
                <p>
                    You have reached the free tier limit of 3 summary / flashcard generations per
                    month.
                </p>
                <p>Please upgrade now to get unlimited ai capacities.</p>
            </div>
            <div className={styles.buttonArea}>
                <Button
                    onClick={() => {
                        onClickUpgradeNow();
                        onClose();
                    }}
                    cssClass={buttonStyles.btn_primary}>
                    Upgrade now
                </Button>
            </div>
        </OverlayModal>
    );
};

export default UpgradeModal;
