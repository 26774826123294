const CopyIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none">
            <rect
                x="7.08331"
                y="7.08301"
                width="7.08333"
                height="7.08333"
                rx="2"
                stroke="#33363F"
            />
            <path
                d="M9.91659 4.73071V4.73071C9.91659 3.68264 9.06695 2.83301 8.01888 2.83301H6.37492C4.93074 2.83301 4.20865 2.83301 3.69505 3.18847C3.49719 3.32542 3.32566 3.49694 3.18872 3.69481C2.83325 4.2084 2.83325 4.93049 2.83325 6.37468V7.79134C2.83325 7.90741 2.83325 7.96544 2.83566 8.01448C2.88613 9.04188 3.70771 9.86346 4.73512 9.91393C4.78415 9.91634 4.84219 9.91634 4.95825 9.91634V9.91634"
                stroke="#33363F"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

export default CopyIcon;
