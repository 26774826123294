import React, { useRef, useState } from "react";
import { languages, summaryModes } from "../../utils/constants";
import HeroIcon from "../HeroIcon";
import Icon from "../Icon";
import styles from "./GenerateSummaryModal.module.css";

function GenerateSummaryModal({ document_id, handleSummaryCreation, appLanguage, showInfoBanner }) {
    const [showDropdownConfig, setShowDropdownConfig] = useState(false);
    const [showDropdownPages, setShowDropdownPages] = useState(false);
    const [showDropdownLanguage, setShowDropdownLanguage] = useState(false);
    const [config, setConfig] = useState("Bullets - detailed");
    const [pages, setPages] = useState("All Pages");
    const [firstPage, setFirstPage] = useState("");
    const [lastPage, setLastPage] = useState("");

    const [showDropdownImages, setShowDropdownImages] = useState(false);
    const [includeImages, setIncludeImages] = useState(true);

    const languageKey = Object.keys(languages).find((key) => languages[key] === appLanguage);
    const [language, setLanguage] = useState(languageKey || languages["EN"]);

    const toggleIncludeImages = (e) => {
        setIncludeImages(e);
    };

    const getPageInterval = () => {
        if ((firstPage === "" && lastPage === "") || (firstPage === "1" && lastPage === "")) {
            return null;
        }
        if (firstPage === "" && lastPage !== "") {
            return [1, lastPage];
        }
        if (firstPage !== "" && lastPage === "") {
            return [firstPage, "MAX"];
        }

        return [firstPage, lastPage];
    };

    const generate = () => {
        if (lastPage !== "" && firstPage !== "" && parseInt(lastPage) < parseInt(firstPage)) {
            console.log(lastPage);
            console.log(firstPage);
            showInfoBanner(
                "Invalid range for pages: Please choose the first page number to be smaller than the last"
            );
            return;
        }

        handleSummaryCreation(
            document_id,
            languages[language],
            summaryModes[config],
            getPageInterval(pages),
            includeImages
        );
    };

    const updatePages = (firstPage, lastPage) => {
        if ((firstPage === "" && lastPage === "") || (firstPage === "1" && lastPage === "")) {
            setPages("All Pages");
            return;
        }
        if (firstPage === "" && lastPage !== "") {
            setPages(`Till Page ${lastPage}`);
            return;
        }
        if (firstPage !== "" && lastPage === "") {
            setPages(`From Page ${firstPage}`);
            return;
        }

        setPages(`${firstPage} to ${lastPage}`);
    };

    const handleFirstPageChange = (event) => {
        const value = event.target.value;
        if (value === "0") {
            showInfoBanner("Please enter a positive number.");
            return;
        }
        if (!isNaN(value)) {
            setFirstPage(value);
            updatePages(value, lastPage);
        } else {
            console.log("Please input numbers only for the page number.");
        }
    };

    const handleLastPageChange = (event) => {
        const value = event.target.value;
        if (!isNaN(value)) {
            setLastPage(value);
            updatePages(firstPage, value);
        } else {
            console.log("Please input numbers only for the page number.");
        }
    };

    return (
        <div className={`${styles.uploadContainer}`}>
            <span className={styles.uploadIcon}>
                <HeroIcon name="TextDocumentIcon" />
            </span>
            <h2 className={styles.uploadTextTitle}>Your summary with just one click</h2>
            <div className={styles.uploadArea}>
                <div className={styles.generateButton}>
                    <button className={styles.generateBtn} onClick={generate}>
                        Generate now
                    </button>
                </div>
                <div className={styles.buttons}>
                    {/* Change Summary-Mode */}
                    <div className={styles.dropdown}>
                        <button
                            className={styles.uploadBtn}
                            onClick={() => setShowDropdownConfig(!showDropdownConfig)}>
                            {config}
                            <Icon name="ChevronDownIcon" />
                        </button>
                        {showDropdownConfig && (
                            <div className={styles.dropdownContent}>
                                {Object.entries(summaryModes).map(([label, item], index) => (
                                    <span
                                        key={index}
                                        className={label === config ? styles.active : ""}
                                        onClick={() => {
                                            setConfig(label);
                                            setShowDropdownConfig(false);
                                        }}>
                                        {label}
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>

                    {/* Change Language */}
                    <div className={styles.dropdown}>
                        <button
                            className={styles.uploadBtn}
                            onClick={() => setShowDropdownLanguage(!showDropdownLanguage)}>
                            {language} <Icon name="ChevronDownIcon" />
                        </button>
                        {showDropdownLanguage && (
                            <div className={styles.dropdownContent}>
                                {Object.entries(languages).map(([code, name], index) => (
                                    <span
                                        key={index}
                                        className={language === code ? styles.active : ""}
                                        onClick={() => {
                                            setLanguage(code);
                                            setShowDropdownLanguage(false);
                                        }}>
                                        {code}
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>

                    {/* Change Images */}
                    <div className={styles.dropdown}>
                        <button
                            className={styles.uploadBtn}
                            onClick={() => setShowDropdownImages(!showDropdownImages)}>
                            {includeImages ? "Images On" : "Images Off"}{" "}
                            <Icon name="ChevronDownIcon" />
                        </button>
                        {showDropdownImages && (
                            <div className={styles.dropdownContent}>
                                <span
                                    className={includeImages ? styles.active : ""}
                                    onClick={() => {
                                        toggleIncludeImages(true);
                                        setShowDropdownImages(false);
                                    }}>
                                    Images On
                                </span>
                                <span
                                    className={!includeImages ? styles.active : ""}
                                    onClick={() => {
                                        toggleIncludeImages(false);
                                        setShowDropdownImages(false);
                                    }}>
                                    Images Off
                                </span>
                            </div>
                        )}
                    </div>

                    {/* Change Pages */}
                    <div className={styles.dropdown}>
                        <button
                            className={styles.uploadBtn}
                            onClick={() => setShowDropdownPages(!showDropdownPages)}>
                            {pages}
                            <Icon name="ChevronDownIcon" />
                        </button>
                        {showDropdownPages && (
                            <div className={styles.dropdownContent}>
                                <span>
                                    <input
                                        className={styles.pageInput}
                                        value={firstPage}
                                        onChange={handleFirstPageChange}
                                        placeholder="1"
                                    />
                                    to
                                    <input
                                        className={styles.pageInput}
                                        value={lastPage}
                                        onChange={handleLastPageChange}
                                        placeholder="100"
                                    />
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GenerateSummaryModal;
