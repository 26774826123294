import React, { useState } from "react";
import Button from "../Button_v2/Button";
import buttonStyles from "../Button_v2/Button_V2.module.css";
import Icon from "../Icon";
import OverlayModal from "./OverlayModal";
import styles from "./downloadmodal.module.css";

const DownloadModal = ({ isVisible, onClose, downloadLink }) => {
    const [copySuccess, setCopySuccess] = useState("");

    const startDownload = () => {
        window.open(downloadLink, "_blank"); // Example of triggering a download
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(downloadLink).then(
            () => {
                setCopySuccess("Copied!");
                setTimeout(() => setCopySuccess(""), 2000); // Reset the message after 2 seconds
            },
            () => {
                setCopySuccess("Failed to copy");
                setTimeout(() => setCopySuccess(""), 2000); // Reset the message after 2 seconds
            }
        );
    };

    if (!isVisible) return null;

    return (
        <OverlayModal title={"Download Link"} isVisible={isVisible} onClose={onClose}>
            <input
                onClick={copyToClipboard}
                className={styles.downloadLink}
                type="text"
                value={downloadLink}
                readOnly
            />
            <span onClick={copyToClipboard}>
                <Icon name="CopyIcon" />
            </span>
            <div className={styles.buttonBar}>
                <Button onClick={startDownload} cssClass={buttonStyles.btn_primary}>
                    Start Download
                </Button>
                <span className={styles.infomessage}>{copySuccess}</span>
            </div>
        </OverlayModal>
    );
};

export default DownloadModal;
