import React from "react";
import styles from "./OverlayModal.module.css";

const OverlayModal = ({ children, isVisible, title, onClose, width }) => {
    if (!isVisible) return null;
    if (!width) width = "600px";

    return (
        <div className={styles.overlay}>
            <div className={styles.modal} style={{ width: width }}>
                <header className={title ? styles.header : styles.no_header}>
                    <span>{title}</span>
                    <button onClick={onClose}>&times;</button>
                </header>
                <div className={styles.content}>{children}</div>
            </div>
        </div>
    );
};

export default OverlayModal;
