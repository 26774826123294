import React from "react";
import OverlayModal from "../OverlayModal/OverlayModal";
import styles from "../OverlayModal/YesOrNoOverlayModal.module.css";

const DeleteButtonFileManager = ({ isVisible, onClose, onReset, headerMessage, message }) => {
    return (
        <OverlayModal title={headerMessage} onClose={onClose} isVisible={isVisible}>
            {message.split("\n").map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
            ))}
            <div className={styles.buttonContainer}>
                <button onClick={onReset} className={`${styles.actionButton} ${styles.redButton}`}>
                    Accept
                </button>
                <button onClick={onClose} className={`${styles.actionButton} ${styles.blueButton}`}>
                    Cancel
                </button>
            </div>
        </OverlayModal>
    );
};

export default DeleteButtonFileManager;
