const FlashcardsIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="66"
            height="65"
            viewBox="0 0 66 65"
            fill="none">
            <mask
                id="mask0_1_1206"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="66"
                height="65">
                <rect x="0.5" width="65" height="65" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1_1206)">
                <path
                    d="M19.1667 29H30"
                    stroke="url(#paint0_linear_1_1206)"
                    stroke-width="3"
                    stroke-linecap="round"
                />
                <path
                    d="M19 36H41"
                    stroke="url(#paint1_linear_1_1206)"
                    stroke-width="3"
                    stroke-linecap="round"
                />
                <path
                    d="M19 42H41"
                    stroke="url(#paint2_linear_1_1206)"
                    stroke-width="3"
                    stroke-linecap="round"
                />
                <path
                    d="M16.5 50.125H43.5C46.3284 50.125 47.7426 50.125 48.6213 49.2463C49.5 48.3676 49.5 46.9534 49.5 44.125V26.875C49.5 24.0466 49.5 22.6324 48.6213 21.7537C47.7426 20.875 46.3284 20.875 43.5 20.875H16.5C13.6716 20.875 12.2574 20.875 11.3787 21.7537C10.5 22.6324 10.5 24.0466 10.5 26.875V44.125C10.5 46.9534 10.5 48.3676 11.3787 49.2463C12.2574 50.125 13.6716 50.125 16.5 50.125Z"
                    stroke="url(#paint3_linear_1_1206)"
                    stroke-width="3"
                />
                <path
                    d="M16 21V21C16 18.1716 16 16.7574 16.8787 15.8787C17.7574 15 19.1716 15 22 15H49C51.8284 15 53.2426 15 54.1213 15.8787C55 16.7574 55 18.1716 55 21V38.75C55 41.0956 55 42.2684 54.3801 43.0763C54.2205 43.2843 54.0343 43.4705 53.8263 43.6301C53.0184 44.25 51.8456 44.25 49.5 44.25V44.25"
                    stroke="url(#paint4_linear_1_1206)"
                    stroke-width="3"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_1_1206"
                    x1="20.1515"
                    y1="28.875"
                    x2="26.7493"
                    y2="35.8688"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#256EFF" />
                    <stop offset="0.5" stop-color="#80A9F9" />
                    <stop offset="1" stop-color="white" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1_1206"
                    x1="21"
                    y1="35.875"
                    x2="26.0504"
                    y2="46.7468"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#256EFF" />
                    <stop offset="0.5" stop-color="#80A9F9" />
                    <stop offset="1" stop-color="white" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1_1206"
                    x1="21"
                    y1="41.875"
                    x2="26.0504"
                    y2="52.7468"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#256EFF" />
                    <stop offset="0.5" stop-color="#80A9F9" />
                    <stop offset="1" stop-color="white" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_1_1206"
                    x1="14.0455"
                    y1="17.219"
                    x2="63.6421"
                    y2="23.6896"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#256EFF" />
                    <stop offset="0.5" stop-color="#80A9F9" />
                    <stop offset="1" stop-color="white" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_1_1206"
                    x1="19.5455"
                    y1="11.344"
                    x2="69.1421"
                    y2="17.8146"
                    gradientUnits="userSpaceOnUse">
                    <stop stop-color="#256EFF" />
                    <stop offset="0.5" stop-color="#80A9F9" />
                    <stop offset="1" stop-color="white" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default FlashcardsIcon;
